import * as R from "ramda";
import React, { useEffect, useRef } from "react";
import useDimensions from "react-cool-dimensions";

/** Interface that the D3 Components (plain TS classes) should implement.
 */
export interface D3Component<ComponentProps> {
  update: (props: ComponentProps) => void;
}

/** Type representing all the info we pass from React to D3
 */
export interface D3ComponentProps<Data, ExtraProps> {
  width: number;
  height?: number;
  data: Data;
  extraProps?: ExtraProps;
}

/** Type representing the info we pass to the D3 Container (React component that updates D3)
 */
export interface D3ContainerProps<Data, ExtraProps>
  extends D3ComponentProps<Data, ExtraProps> {
  /** A function which, given a DOM node, will initialize the underlying D3
   * component and attach it to the node.
   */
  D3ComponentFactory?: (
    node: HTMLElement,
    props?: D3ContainerProps<Data, ExtraProps>
  ) => D3Component<D3ComponentProps<Data, ExtraProps>>;
  onInitialize?: (component: any) => void;
  // w/h are optionally set by the caller, otherwise it will use the container size
  fixedWidth?: number;
  fixedHeight?: number;
}

/** Wrapper for D3 components.
 *
 * The first generic type represents the data which will be fed into the graph.
 *
 * The second generic type represents props which are passed to the component.
 *
 * The wrapper accepts all HTML props that can apply to divs
 */
export function D3Container<Data, ExtraProps = {}>({
  D3ComponentFactory,
  onInitialize = R.always(undefined),
  data,
  extraProps,
  fixedWidth,
  fixedHeight,
  ...props
}: React.HTMLAttributes<HTMLDivElement> &
  Omit<
    D3ContainerProps<Data, ExtraProps>,
    "width" | "height"
  >): React.ReactElement | null {
  const d3Component =
    useRef<D3Component<D3ComponentProps<Data, ExtraProps>>>(null);
  const { observe, width, height } = useDimensions();

  const desiredWidth = fixedWidth || width;
  const desiredHeight = fixedHeight || height;

  const mountD3 = React.useCallback((el) => {
    if (el) {
      d3Component.current = D3ComponentFactory(el, {
        data,
        width: desiredWidth,
        height: desiredHeight,
        extraProps: extraProps || ({} as ExtraProps),
      });
      observe(el);
      onInitialize(d3Component.current);
    }
  }, []);

  useEffect(() => {
    if (d3Component.current && width > 0 && height > 0) {
      d3Component.current.update({
        data,
        extraProps: extraProps || ({} as ExtraProps),
        width: desiredWidth,
        height: desiredHeight,
      });
    }
  }, [data, width, height, extraProps, d3Component.current]);

  return <div {...props} ref={mountD3} />;
}
