import type {
  D3ComponentProps,
  D3ContainerProps,
} from "components/D3Container";
import type { D3LabelledBand } from "data/d3";

import * as d3 from "d3";
import { D3RangeAdheranceChart } from ".";
import { NextRouter } from "next/router";
import { Benchmark, RegionRectangle } from "./data/regions";
import { HydratedEmployee } from ".generated/models";

export enum RangeAdheranceChartEvent {
  ExpansionStateChange = "expansion-state-change",
  GoToPlan = "go-to-plan",
  FocusRegion = "focus-region",
}

export interface Params {
  props: PaybandChartComponentProps;
  scales: Scales;
  ctx: D3RangeAdheranceChart;
}

export interface Margin {
  top: number;
  bottom: number;
  left: number;
  right: number;
}

export interface IconData {
  key: string;
  subkey: string;
  expanded: boolean;
}

export interface Data extends HydratedEmployee {
  subkey?: string;
}

export interface Grouped {
  size: number;
  pay: number;
  pay_base: number;
  actual: number | null;
  subkey: string;
}

export interface Meta {
  data: Data[];
  grouped: Grouped[];
  key: string;
}

export interface ComputedProps {
  groupedData: Meta[];
  benchmarks: Benchmark[];
  expandedState: { [key: string]: boolean };
  formatter: Intl.NumberFormat;
  getYValue: (d: Data) => string;
  getYKey: (d: Data) => string;
  paybands: any[];
  payAttr: string;
  paybandMinAttr: string;
  paybandMaxAttr: string;
  hourly: boolean;
  regions: { [key: string]: RegionRectangle[] };
}

export enum BenchmarkType {
  Local = "local",
  Base = "base",
}

export type Payband = Record<string, unknown>[];
export type PaybandFn = (d: Data[]) => Payband[];
export interface PaybandFnProps {
  y: string | ((d: Data) => string);
  yKey: (d: Data) => string;
  min: string;
  max: string;
}

export interface D3Props {
  xAttr: string;
  xLabel: string;
  xRangeMinAttr?: string;
  xRangeMaxAttr?: string;
  benchmarkType?: BenchmarkType;
  yAttr: string | ((d: Data) => string);
  yKeyAttr?: string | ((d: Data) => string);
  yHighlight?: string[];
  highlightColor?: string;
  colorScale: d3.ScaleOrdinal<string, string>;
  getGroupColor: (
    d: Data | Data[],
    props: PaybandChartComponentProps
  ) => string;
  colorAttr: string;
  renderCompact?: boolean;
  router: NextRouter;
  currency: string;
  computed: ComputedProps;
  current?: string;
  hideBenchmarks?: boolean;
  hideToggleControls?: boolean;
  showLegend?: boolean;
  disableTransitions?: boolean;
  useHourly?: boolean;
  getPaybands?: (props: PaybandFnProps) => PaybandFn;
  emptyGroups?: string[];
  margin?: Margin;
  barHeight?: number;
  minGroupCount?: number;
}

export interface Scales {
  x: d3.ScaleLinear<number, number>;
  y: D3LabelledBand;
  legend: d3.ScaleOrdinal<string, string>;
}

export type PaybandChartComponentProps = D3ComponentProps<Data[], D3Props>;
export type RangeAdheranceChartProps = Omit<
  D3ContainerProps<Data[], Omit<D3Props, "computed">>,
  "width" | "height"
>;
export type PaybandData = Data;

export type GroupedSelection = d3.Selection<
  d3.BaseType,
  Grouped,
  d3.BaseType,
  Meta
>;
export type DataSelection = d3.Selection<d3.BaseType, Data, d3.BaseType, Meta>;

export enum TenureCategory {
  Less = "less",
  More = "more",
  Same = "same",
  Multiple = "multiple",
}
export const TenureCategoryLabels = {
  [TenureCategory.Less]: "paid less",
  [TenureCategory.More]: "paid more",
  [TenureCategory.Same]: "same",
  [TenureCategory.Multiple]: "multiple",
};
