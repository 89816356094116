import * as d3 from "d3";
import * as R from "ramda";
import * as Style from "style";
import { WORK_SANS } from "./fonts";
import EventEmitter from "events";

export interface Margin {
  top: number;
  bottom: number;
  left: number;
  right: number;
}

export interface EventHandlers<D, P, S> {
  click?: (props: P, scales: S) => (e: MouseEvent, d: D) => void;
  mouseover: () => void;
  mousemove: (props: P, scales: S) => (e: MouseEvent, d: D) => void;
  mouseleave: (e: MouseEvent) => void;
}

export type RootSelection<Data> = d3.Selection<
  SVGSVGElement,
  Data,
  null,
  unknown
>;
export type GroupSelection<Data> = d3.Selection<
  SVGGElement,
  Data,
  null,
  unknown
>;

type DrawFn<D, C, S, B> = (
  container: GroupSelection<D>,
  { props, scales, ctx }: { props: C; scales: S; ctx: B }
) => void;

export abstract class BaseD3Component<Data, ComponentProps, Scales> {
  svg: RootSelection<Data>;
  props: ComponentProps;
  margin: Margin;
  emitter: EventEmitter;
  width: number;
  height: number;

  /** Called before updating and redrawing the graph.
   *
   * Allows for any manipulation of data or changes to dimensions before drawing.*/
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected beforeUpdate(): void {}

  abstract getGraphElements(): {
    [key: string]: any;
    // TODO: Use this type or a similar one
    // [key: string]: DrawFn<
    //   Data,
    //   ComponentProps,
    //   Scales,
    //   BaseD3Component<Data, ComponentProps, Scales>
    // >;
  };
  abstract createScales(props: ComponentProps): Scales;
  abstract getGraphAxes(): {
    [key: string]: DrawFn<
      Data,
      ComponentProps,
      Scales,
      BaseD3Component<Data, ComponentProps, Scales>
    >;
  };
  abstract getBaseMargin(): Margin;

  constructor(container: HTMLElement, props: ComponentProps) {
    this.svg = d3
      .select<HTMLElement, Data>(container)
      .append("svg")
      .attr("class", "milio-graph");
    // Embed the font directly so that it works on export to PNG/powerpoint
    this.svg
      .append("style")
      .text(
        `@font-face { font-family: ${Style.Text.FontFamily.Primary}; font-size: ${Style.Text.Size.Base}; font-weight: ${Style.Text.Weight.Secondary}; font-style: ${Style.Text.Style.Primary}; src: url(${WORK_SANS}) format('woff2') ;}`
      );
    this.props = props;
    this.margin = this.getBaseMargin();
    this.emitter = new EventEmitter();
    this.setup();
  }

  setup(): void {
    const axes = this.getGraphAxes();
    const elements = this.getGraphElements();
    // create a <g> tag to contain each axis. Happens before graph elements so that gridlines are drawn underneath.
    R.forEach((key: string) => {
      this.svg.append("g").attr("class", `${key} axis`);
    }, R.keys(axes));

    // create a <g> tag to contain each graph element (lines, bars, etc.)
    R.forEach((key: string) => {
      this.svg.append("g").attr("class", key);
    }, R.keys(elements));
  }

  update(props: ComponentProps): void {
    this.props = props;
    this.beforeUpdate();
    const axes = this.getGraphAxes();
    const elements = this.getGraphElements();

    R.forEachObjIndexed(
      (
        axis: DrawFn<
          Data,
          ComponentProps,
          Scales,
          BaseD3Component<Data, ComponentProps, Scales>
        >,
        key: string
      ) => {
        const scales = this.createScales(this.props);
        const group = this.svg.select<SVGGElement>(`g.${key}`);
        axis(group, { props: this.props, scales, ctx: this });
      },
      axes
    );

    R.forEachObjIndexed(
      (
        elem: DrawFn<
          Data,
          ComponentProps,
          Scales,
          BaseD3Component<Data, ComponentProps, Scales>
        >,
        key: string
      ) => {
        // we re-compute scales after axes are called as some axes change the margins dynamically
        const scales = this.createScales(this.props);
        const group = this.svg.select<SVGGElement>(`g.${key}`);
        elem(group, { props: this.props, scales, ctx: this });
      },
      elements
    );
  }

  on(key: string, callback: (...args: any[]) => void): void {
    this.emitter.on(key, callback);
  }

  removeAllListeners(key?: string): void {
    this.emitter.removeAllListeners(key);
  }
}
